<template>
  <div id="calendar">
    <full-calendar
      ref="calendar"
      :config="config"
      :events="events"
      @changeMonth="changeMonth"
    />
  </div>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
export default {
  components: { FullCalendar },
  data () {
    return {
      events: [
        {
          start: '2022-06-01',
          title: '1场'
        },
        {
          start: '2022-06-02',
          title: '1场'
        },
        {
          start: '2022-06-03',
          title: '1场'
        },
        {
          start: '2022-06-04',
          title: '1场'
        },
        {
          start: '2022-06-05',
          title: '1场'
        }
      ],
      header: { left: 'today', center: 'prev, title, next', right: '' },
      config: {
        firstDay: '0', // 以周日为每周的第一天
        locale: 'zh-cn', // 语言
        defaultView: 'month', // 默认按月显示
        height: 'auto', // 高度
        // buttonText: { today: "今天", month: "月", week: "周", day: "日" },
        buttonText: { today: '天' },
        header: { left: '', center: 'prev, title, next', right: '' },
        // header: {left:'prev,next,today', center: 'title',right:'custom'},
        fixedWeekCount: false, // 显示的周数(如果为true，显示为6周高，否则将有4、5、6周，取决于月份)
        theme: false, // 是否允许使用jquery的ui主题
        // slotLabelFormat:'H:mm', // axisFormat 'H(:mm)'
        // slotLabelInterval:1,
        allDayDefault: false,
        views: {
          month: {
            titleFormat: 'YYYY年MMM'
          }
          // day: {
          //   titleFormat: 'YYYY年MMMDD日 dddd'
          // }
        },
        // monthNames: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        // monthNamesShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        // dayNames: ["日", "一", "二", "三", "四", "五", "六"],
        dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
        // slotDuration:'00:30:00',
        // slotLabelFormat: 'H:mm:ss',
        // minTime: '00:00:00',
        // maxTime: '23:59:59',
        editable: false, // 是否允许修改事件
        // selectable: false,//是否允许用户单击或者拖拽日历中的天和时间隙
        // eventLimit: false, // 限制一天中显示的事件数，默认false
        // allDaySlot:false, //是否显示allDay
        // displayEventEnd: false,//是否显示结束时间
        // allDayText: '全天',
        navLinks: true, // 允许天/周名称是否可点击
        eventClick: this.eventClick, // 点击事件
        dayClick: this.dayClick, // 点击日程表上面某一天
        eventRender: this.eventRender
      }
    }
  },

  methods: {
    // 点击事件
    eventClick (event, jsEvent, pos) {
      console.log('eventClick', event, jsEvent, pos)
    },
    // 点击当天
    dayClick (day, jsEvent) {
      console.log('dayClick', day, jsEvent)
    },
    changeMonth () {}
  }
}
</script>
